@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/indigo.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/purple.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
p {
    all: unset;
}

.NavigationMenuRoot {
    margin: 0 var(--space-1);
    position: relative;
    z-index: 1;
}

.NavigationMenuList {
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: var(--space-1);
    margin: 0;
}

.NavigationMenuList>li {
    position: relative;
}

.NavigationMenuList>li .rt-Badge {
    display: inline-block;
    margin-left: auto;
}

.NavigationMenuLink {
    align-items: center;
    padding: var(--space-2);
    width: 100%;
    outline: none;
    user-select: none;
    color: var(--gray-11);
    border-radius: var(--radius-2);
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-2);
    display: flex;
    text-decoration: none;
    width: 100%;
    position: relative;
    white-space: nowrap;
}

.NavigationMenuLink .icon {
    margin-right: var(--space-2);
    height: 20px;
    width: 20px;
}

.NavigationMenuLink .rt-Text {
    flex: 1;
    line-height: 1;
}

.NavigationMenuLink:focus,
.NavigationMenuLink:hover,
.NavigationMenuLink[data-state='open'] {
    background-color: var(--gray-3);
    color: var(--gray-12);
}

.NavigationMenuLink:focus .cls-1,
.NavigationMenuLink:hover .cls-1,
.NavigationMenuLink[data-state='open'] .cls-1 {
    fill: var(--gray-12);
}

.NavigationMenuGroup {
    padding-left: var(--space-2);
    white-space: nowrap;
}

.NavigationMenuContent {
    position: absolute;
    top: 0;
    left: 215px;
    width: 100%;
    padding: var(--space-1);
    box-shadow: var(--shadow-6);
    background-color: var(--color-panel-solid);
    border-left: var(--space-3) solid var(--gray-3);
    border-radius: 6px;
    height: var(--radix-navigation-menu-viewport-height);
    width: var(--radix-navigation-menu-viewport-width);
}

.NavigationMenuContent[data-state='open'] {
    animation: fadeIn 300ms ease;
}

.NavigationMenuContent[data-motion='closed'] {
    animation: fadeOut 300ms ease;
}

@media only screen and (min-width: 600px) {
    .NavigationMenuContent {
        width: auto;
    }
}

@keyframes enterFromBottom {
    from {
        opacity: 0;
        transform: translateY(200px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes enterFromTop {
    from {
        opacity: 0;
        transform: translateY(-200px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes exitToTop {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(200px);
    }
}

@keyframes exitToBottom {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-200px);
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: rotateY(-30deg) scale(0.9);
    }

    to {
        opacity: 1;
        transform: rotateY(0deg) scale(1);
    }
}

@keyframes scaleOut {
    from {
        opacity: 1;
        transform: rotateY(0deg) scale(1);
    }

    to {
        opacity: 0;
        transform: rotateY(-10deg) scale(0.95);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
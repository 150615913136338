.box {
  >:first-child {
    >:first-child {
      overflow: unset;
    }

    >:last-child {
      margin-top: 54px;
      max-height: 280px;

      ul {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }
}

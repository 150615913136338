:root, .light, .light-theme {
    --lime-1: #fcfdfa;
    --lime-2: #f7faf4;
    --lime-3: #e8f8dc;
    --lime-4: #daf2c5;
    --lime-5: #cae9b0;
    --lime-6: #b9dc9a;
    --lime-7: #a3cb80;
    --lime-8: #87b659;
    --lime-9: #aae66f;
    --lime-10: #a0db64;
    --lime-11: #577c30;
    --lime-12: #304120;
  
    --lime-a1: #66990005;
    --lime-a2: #468c000b;
    --lime-a3: #58cc0023;
    --lime-a4: #5dc6003a;
    --lime-a5: #54b8004f;
    --lime-a6: #4fa70065;
    --lime-a7: #4797007f;
    --lime-a8: #478f00a6;
    --lime-a9: #69d30090;
    --lime-a10: #63c4009b;
    --lime-a11: #305e00cf;
    --lime-a12: #122600df;
  
    --lime-contrast: #1b2611;
    --lime-surface: #f5f9f1cc;
    --lime-indicator: #aae66f;
    --lime-track: #aae66f;
  }
  
  @supports (color: color(display-p3 1 1 1)) {
    @media (color-gamut: p3) {
      :root, .light, .light-theme {
        --lime-1: oklch(99.3% 0.0043 131.2);
        --lime-2: oklch(98.2% 0.0096 131.2);
        --lime-3: oklch(96% 0.0407 131.2);
        --lime-4: oklch(93.2% 0.0647 131.2);
        --lime-5: oklch(89.8% 0.0825 131.2);
        --lime-6: oklch(85.4% 0.0955 131.2);
        --lime-7: oklch(79.5% 0.1084 131.2);
        --lime-8: oklch(72.3% 0.1338 131.2);
        --lime-9: oklch(85.8% 0.1626 131.2);
        --lime-10: oklch(82.7% 0.1626 131.2);
        --lime-11: oklch(54.2% 0.1133 131.2);
        --lime-12: oklch(35% 0.0565 131.2);
  
        --lime-a1: color(display-p3 0.4118 0.6078 0.0196 / 0.02);
        --lime-a2: color(display-p3 0.3765 0.5569 0.0196 / 0.044);
        --lime-a3: color(display-p3 0.4 0.7608 0.0078 / 0.13);
        --lime-a4: color(display-p3 0.4118 0.7412 0.0039 / 0.212);
        --lime-a5: color(display-p3 0.3686 0.6902 0.0039 / 0.291);
        --lime-a6: color(display-p3 0.3333 0.6196 0.0039 / 0.369);
        --lime-a7: color(display-p3 0.298 0.5569 0.0039 / 0.467);
        --lime-a8: color(display-p3 0.2863 0.5216 0 / 0.604);
        --lime-a9: color(display-p3 0.4431 0.7922 0 / 0.506);
        --lime-a10: color(display-p3 0.4039 0.7255 0 / 0.546);
        --lime-a11: color(display-p3 0.1843 0.3255 0 / 0.769);
        --lime-a12: color(display-p3 0.0706 0.1333 0 / 0.859);
  
        --lime-contrast: #1b2611;
        --lime-surface: color(display-p3 0.9608 0.9765 0.9451 / 0.8);
        --lime-indicator: oklch(85.8% 0.1626 131.2);
        --lime-track: oklch(85.8% 0.1626 131.2);
      }
    }
  }